// in case that key-navigation in Firefox on MacOS is buggy (does not display focus-visible on <a> Tags), go to Mac settings::after
//  settings/keyboard/shortcuts, check the checkbox below saying "Use keyboard navigation to move focus between controls"
// https://stackoverflow.com/questions/3846868/why-wont-tabindex-work-with-firefox

// focus = for elements that are clicked by mouse (in focus), default style set to nothing (no outline like most browser-defaults)
* {
  :focus {
    outline: none;
    box-shadow: none;
  }

  // focus-visible = for elements reachable by key-navigation
  // box-shadow (set to "inset" in variable) is used instead of outline, so that elements do not wiggle around when focused, because they enlarge with outline (esp. seen in a grid of elements like filter-items)
  :focus-visible {
    box-shadow: $box-shadow-focused !important;
    outline: none !important;
  }
}

/* #region focus:visible mixins */

// for all clickable elements that are not addressed by default oder with a different default box-shadow
@mixin focus-visible--forced {
  box-shadow: $box-shadow-focused !important;
}

// for all clickable elements without box-shadow in :focus-visible
// and for SVGs without a-tag, e.g. "?"-faq-icon in collapsable-card header (inactive project)
@mixin focus-visible--no-box-shadow {
  box-shadow: none !important;
  color: $color-primary !important;
}

// for all clickable elements where box-shadow with "inset" attribute is overlapped
@mixin focus-visible--no-overlapping {
  box-shadow: 0 0 0 3px $color-primary !important;
}

/* #endregion focus:visible mixins */