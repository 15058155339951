// CSS-styles for the map based on leaflet and leaflet draw
// NOTE: leaflet + leaflet draw styles are also imported at MapEdit.tsx
// to use those styles only when they are really needed

@charset "UTF-8";

// map must get a height to be shown
// negative margin resets height of save-button
.map-style {
  height: 80vw;
  max-height: 40rem;

  &--edit-mode {

    .map-style__tooltip {
      display: none;
    }
  }
}

// save-button for the whole map entry
.map-save-button {
  position: relative;
  z-index: 40000;
  top: 1rem;
  left: 4rem;
}

.leaflet-bar {
  background-color: #FFF !important;
  border-width: 1px !important;

  // size of toolbar-buttons
  a {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
    border-image: none;
    border-bottom-width: 0 !important;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-light !important;
    }

    &:hover {
      background-color: $color-gray-light !important;
    }
  }
}

/* #region leaflet-draw-toolbar */

.leaflet-control-zoom-in {
  background-size: 18px !important;
}

// the (poly)line-button in the toolbar
.leaflet-draw-draw-polyline {
  background-image: url(/assets/icons/map-line.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 18px !important;
  background-position: center !important;
}

// the polygon-button in the toolbar
.leaflet-draw-draw-polygon {
  background-image: url(/assets/icons/map-polygon.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 18px !important;
  background-position: center !important;
}

// the marker-button in the toolbar
.leaflet-draw-draw-marker {
  background-image: url(/assets/icons/map-marker-sw.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 22px !important;
  background-position: center !important;
}

// the edit-button in the toolbar
.leaflet-draw-edit-edit {
  background-image: url(/assets/icons/pencil.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 18px !important;
  background-position: center !important;
}

// the delete-button in the toolbar
.leaflet-draw-edit-remove {
  background: url(/assets/icons/trash.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 18px !important;
  background-position: center !important;
}

/* #endregion leaflet-draw-toolbar */

// submenu
.leaflet-draw-actions {
  left: 36px !important;
  border: 1px solid #ccc !important;
  border-radius: 0 4px 4px 4px;

  a {
    font-size: 14px !important;
    line-height: 35px !important;
    height: 35px !important;
    border-bottom: none !important;
    border-left: none !important;
    color: $color-dark !important;
    top: 0px !important;
    background-color: unset !important;


    &:hover,
    &:focus,
    &:active {
      border-bottom: 5px solid $color-primary !important;
    }
  }

  li {
    display: block !important;
    border-radius: 0;
    margin-bottom: 0;
    background-color: #FFF !important;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-light;
    }

    &:first-child {
      border-radius: 0 4px 0 0 !important;
    }

    &:last-child {
      border-radius: 0 0 4px 4px !important;
    }

    &:only-child {
      border-radius: 0 4px 4px 4px !important;
    }
  }

  @media screen and (min-width: $sm) {
    border-radius: 0 4px 4px 0 !important;

    li {
      display: inline-block !important;

      &:not(:last-child) {
        border-right: 1px solid $color-gray-light;
        border-bottom: 0;
      }

      &:last-child {
        border-radius: 0 4px 4px 0 !important;
      }

      &:first-child {
        border-radius: 0 !important;
      }

      &:only-child {
        border-radius: 0 4px 4px 4px !important;
      }
    }
  }
}

// marker in edit-mode
.leaflet-edit-marker-selected {
  background-color: transparent !important;
  border: 4px dashed $color-primary !important;
}

// hides the tooltip of an element in the edit mode
.leaflet-pane:has(.leaflet-popup-pane>div) {
  .leaflet-tooltip-pane {
    display: none;
  }
}

// colored overlay for polylines and polygons
.leaflet-pane .leaflet-overlay-pane {
  color: $color-primary;
}

// copyright Leaflet and OSM
// @ todo: Ist es rechtlich möglich, das auszublenden? Wenn ja a entfernen und display: none einkommentieren
.leaflet-control-attribution a {
  border-bottom: none;
  // display: none;
}