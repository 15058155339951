// common buttons
// use-case specific buttons are located in use-case files

@mixin btn {
  border-radius: 9999px;
  font-family: $font-heading;
  padding: 0.8rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  color: $color-dark;
  user-select: none;
  transition: 0.3s;
  vertical-align: middle;
  border: solid 1px $color-white;
  background-color: $color-gray-lighter;
  transition: 0.3s;
  cursor: pointer;
  gap: 0.5rem;
  text-decoration: none;
  width: auto;

  &:disabled {
    opacity: .4;
    cursor: default;
    color: inherit;
  }

  &:hover {
    background-color: #d4d4d4;
    border: solid 1px $color-white;
    color: $color-dark;
    text-decoration: none;
  }

  &:focus-visible {
    color: inherit;
    // bootstrap changes the background-color in focus:visible for all btn
    // revert removes this overwriting style 
    background-color: revert;
  }

  &:active {
    color: inherit !important;
  }
}

// general button styles and overwriting bootstrap styles (.btn-secondary)
button,
.btn,
.btn-secondary {
  @include btn;
}

// groups multiple btn
.btn-flex-layout {
  gap: 0.5rem !important;
  display: flex;
  flex-wrap: wrap;
}

/* #region button-types */

.primary-btn {
  @include btn;
  border: none !important;
  font-weight: bold;
  background-image: $background-image;
  color: $color-on-primary;
  height: 3.25rem;

  &:hover {
    filter: brightness(1.1);
  }

  // undo :hover for disabled primary-btn
  &:hover:disabled {
    filter: unset;
  }
}

.page-navigation-btn {
  @include btn;

  background-color: $color-gray-lighter;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  border: solid 1px $color-white;
  min-height: 36px;
  gap: 0.5rem;

  &:hover {
    background-color: $color-gray-light;
  }

  svg {
    margin-right: unset;
  }
}

.form-btn {
  @include btn;
  font-size: 0.875rem;
  padding: 0.4rem 1rem;
  border: 1px solid $color-dark !important;
  background-color: $color-white;
  font-weight: bold;
  height: 2.125rem;

  &:active {
    color: #000 !important;
    box-shadow: inset 0px 0px 0px 2px #292929 !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: #f3f3f3;
  }

  // undo :hover for disabled form-btn
  &:hover:disabled {
    background-color: transparent !important;
  }
}

.round-icon-btn {
  padding: 0.8rem;
  background-color: $color-white;
  box-shadow: inset 0 0 0 1px $color-dark;

  &:hover {
    background-color: $color-gray-lighter;
  }

  // @todo: klären, @linda: soll der Button den SVG-Abstand unsetten oder sollen andere Buttons
  // den SVG-Abstand für sich setzen?
  // gegen ersteres spricht, dass Erweiterungen an einer allgemeinen SVG-Definition
  // nicht automatisch zurückgesetzt werden bei den Buttons, die diese Erweiterungen auch nicht haben wollen,
  // sondern immer alle Button-Typen zusätzlich angefasst werden müssen
  svg {
    margin-right: unset;
  }

  &:focus-visible {
    @include focus-visible--forced;
  }

  &--active {
    background-color: $color-primary;
    background-image: $background-image;
    color: $color-on-primary;
    box-shadow: none;
    border: none;

    &:hover {
      box-shadow: none;
      border: none;
      background-image: radial-gradient(circle at top left, $color-gray-lighter, $color-gray-lighter)
    }
  }

  &--count {
    border-radius: 99px;
    background-color: $color-white;
    border: 1px solid $color-dark;
    position: relative;
    height: fit-content;
    padding: 0.1rem 0.4rem;
    font-size: 0.813rem;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-left: 35px;
    margin-top: 43px;
    text-align: center;
    position: absolute;

    &--active {
      background-color: $color-primary;
      background-image: $background-image;
      color: $color-on-primary;
      box-shadow: none;
      border: none;
    }
  }
}

/* #endregion button-types */

/* #region filter buttons */

.filteritem-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media screen and (max-width: $sm) {
    gap: 0.3rem;
  }
}

@mixin filteritem {
  transition: 0.2s;
  border: none;
  display: inline-block;
}

// filter with icon (status filter)
.icon-filteritem {
  @include filteritem;

  scale: 50%;
}

// filter with text (categories)
.text-filteritem {
  @include filteritem;

  background-color: $color-gray-lighter;
  border-radius: 9999px;
  padding: 0.4rem 1rem;
  color: $color-dark;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  white-space: nowrap;
  height: 100%;

  &:hover {
    background-color: $color-gray-light;
    border: none;
  }

  &:disabled {
    background-color: $color-gray-lighter;
    color: inherit;
  }

  // undo :hover for disabled text-filteritem
  &:hover:disabled {
    background-color: $color-gray-lighter;
  }

  &--active {
    box-shadow: inset 0px 0px 0px 2px $color-dark !important;

    &:focus-visible {
      @include focus-visible--no-overlapping;
    }
  }

  &--small {
    font-size: .875rem;
  }
}

// filter with image (SDGs)
.image-filteritem {
  @include filteritem;

  border-radius: 0;
  padding: 0;
  opacity: 1;
  border: none;
  line-height: 0;

  &:hover {
    opacity: 0.5;
    border: none;
    background-color: transparent;
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    background-color: revert;
  }

  &:focus-visible {
    @include focus-visible--no-overlapping;
  }

  @media screen and (max-width: $sm) {
    display: inline;
    scale: 70%;

    .btn:not(:first-child) {
      margin-left: 1rem;
    }
  }

  &--active {
    opacity: 1 !important;
    border: none;
    box-shadow: 0px 0px 0px 2px $color-dark !important;

    &:focus-visible {
      @include focus-visible--no-overlapping;
    }
  }
}

/* #endregion filter buttons */

.scroll-to-top-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 99;
  background-image: $background-image;
  box-shadow: $box-shadow-default;
  display: block;
  border: none;
  padding: 0.5rem;

  &--show {
    display: block;
  }

  &--hide {
    display: none;
  }

  &:hover {
    border: none;
    opacity: 0.8;
  }
}