// style of link-underline 
// excludes certain classes from having a link-underline, for single instances class no-link-underline is added at the element
a:not(.btn, .workflow__box, .image-filteritem, .actionicon, .no-link-underline),
// for elements, that should get an underline even though they don't have an <a> tag
.looks-like-a-link {
  transition: all 0.175s ease-in-out;
  border-bottom: 2px solid;
  border-image: $background-image;
  border-image-slice: 1 0;

  &:hover {
    border-bottom-width: 4px;
  }
}

// overwrites bootstrap styles: makes text inside <a> Tag appear normal and not in color-primary
a:not(.btn),
a:not(.btn),
a:not(.btn):visited {
  color: inherit;
  text-decoration: none;
  position: relative;
  display: inline;
}