// bootstrap-classes
.dropdown {

  .btn {
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    transition: all linear 0.125s;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: transparent;
      color: $color-primary;
      box-shadow: none;
      border: none;
    }

    &:focus-visible {
      @include focus-visible--no-box-shadow;
    }
  }
}

.dropdown-menu {
  border: none;
  background-color: $color-primary;
  background-image: $background-image;
  box-shadow: $box-shadow-menu;
  text-align: center;
  margin-right: -12px;
  margin-top: 13px;
  z-index: 10000;

  .dropdown-item {
    padding: 0.3rem 0;
    font-family: $font-heading;
    color: $color-on-primary;
    border-radius: 0;

    &:hover,
    &:active {
      background: #ffffff80;
      color: $color-dark;
      border: none;
      border-radius: 0;
    }

    &:focus {
      background-color: #ffffff80;
    }

    * {
      display: block !important;
      padding: 0.75rem 1.5rem;
      color: inherit;
      border: none;

      &:hover,
      &:visited,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.dropdown-menu::before {
  position: absolute;
  content: "";
  top: -13px;
  right: 13px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid $color-primary-gradient;
}