@import "../../node_modules/react-quill/dist/quill.snow";

/**
 * Styles for the rich text editor (currently Quill)
 */
.quill {
  display: block;
}

.ql-editor {
  background-color: $color-gray-lighter;
}

.rte-container {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: $color-gray-lighter;

  .ql-container.ql-snow {
    font-size: 15px;
    border: none;
  }

  .ql-toolbar.ql-snow {
    border: none;
    background: $color-gray-lighter;
    color: white;
    border-bottom: 2px solid black;
    padding-top: 0px;
    padding-bottom: 3px;
    margin-bottom: 5px;
  }

  .ql-snow .ql-picker {
    border: 1px solid black;
    border-radius: 3px;
  }
}

.rte-active {
  background-color: $color-gray-lighter;
  outline: 0;
  box-shadow: 0 0 0.2rem 0.2rem adjust_color($color-primary, $alpha: -0.5);
}

// bar below the editor showing the number of current characters
.ql-textlength {
  font-size: 0.8em;
  line-height: 0.8em;
  margin-top: 0.7em;
}

/* fix multi-line placeholder overflow */
.ql-editor.ql-blank::before {
  content: none;
}

.ql-editor.ql-blank::after {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  display: block;
  font-style: italic;
  margin-top: -1.42em;
  min-height: 1em;
  pointer-events: none;
}

// for displaying a number next to a title
.number-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  //@todo Linda: prüfen, ob man rte-content auflösen kann (beide Instanzen in dieser Datei), da dies unsere eigenen Styles sind
  .rte-content {
    margin: 0em;
    padding-left: 1rem;
  }
}

// for displaying the formatted content
.rte-content {
  margin-bottom: 1rem;

  p {
    margin: 0
  }

  .ql-size-small {
    font-size: 0.8em;
  }

  .ql-size-large {
    font-size: 1.4em;
  }

  .ql-size-huge {
    font-size: 2em;
  }
}



/* #region rte--all-classes */
//see doc/styles.md add keyword "overwritten-external-styles" for more information

/*
.ql-container
.ql-disabled 
.ql-tooltip
.ql-editor
.ql-clipboard
.ql-direction-rtl
.ql-indent-1
.ql-indent-2
.ql-indent-3
.ql-indent-4
.ql-indent-5
.ql-indent-6
.ql-indent-7
.ql-indent-8
.ql-indent-9
.ql-video
.ql-align-center
.ql-align-right
.ql-bg-black
.ql-bg-red
.ql-bg-orange
.ql-bg-yellow
.ql-bg-green
.ql-bg-blue
.ql-bg-purple
.ql-color-white
.ql-color-red
.ql-color-orange
.ql-color-yellow
.ql-color-green
.ql-color-blue
.ql-color-purple
.ql-font-serif
.ql-font-monospace
.ql-size-small
.ql-size-large
.ql-size-huge
.ql-align-justify
.ql-align-right
.ql-blank
.ql-snow
.ql-toolbar
.ql-picker-label
.ql-active
.ql-picker-item
.ql-selected
.ql-stroke
.ql-fill
.ql-stroke-miter
.ql-out-bottom
.ql-out-top
.ql-snow 
.ql-flip
.ql-formats
.ql-empty
.ql-even
.ql-thin
.ql-transparent
.ql-direction
.ql-syntax
.ql-picker
.ql-picker-label
.ql-picker-options
.ql-expanded
.ql-color-picker
.ql-icon-picker
.ql-header
.ql-font 
.ql-size
.ql-color
.ql-preview
.ql-action
.ql-remove
.ql-editing
*/

/* #endregion rte--all-classes */