// FormikFieldArrays, e.g. goals, places, feedback-invitation questions

/* #region single-input-array */
.single-input-array {
  display: flex;
  align-items: center;

  .inputfield {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mb-3 {
    margin-bottom: 0.1rem !important;
  }
}

/* #endregion single-input-array */

/* #region multi-input-array */

.multi-input-array-container {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  align-items: flex-end;
}

.multi-input-array {

  &--horizontal,
  &--vertical {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .input-container {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .inputfield {
      display: flex;

      .mb-3 {
        margin-bottom: -0.3rem !important;
      }
    }
  }

  &--horizontal {
    margin-left: 2rem;

    .input-container {

      .inputfield {

        &__icon {
          position: absolute;
          margin-left: -2rem;
          margin-top: 0.2rem;
        }
      }
    }
  }

  &--vertical {
    .inputfield {
      gap: 1rem;
    }
  }
}

@media screen and (min-width: $md) {

  .multi-input-array-container {
    margin-bottom: 2rem;
  }

  .multi-input-array-container>.multi-input-array--horizontal,
  .multi-input-array--horizontal {
    align-items: flex-end;
    flex-wrap: nowrap;
    gap: 2.5rem;
  }

  .multi-input-array--horizontal .inputfield,
  .multi-input-array--horizontal .input-container {
    margin-bottom: 0 !important;
  }
}

/* #endregion multi-input-array */

// .invalid-form-input for all arrays
// prüfen, ob diese Styles verallgemeinert werden können
// siehe Ticket: https://futureprojects.atlassian.net/browse/FCP-1574
.single-input-array,
.multi-input-array--horizontal,
.multi-input-array--vertical {

  .invalid-form-input {
    width: 100%;
    margin-top: -2em;
    font-size: 80%;
    color: $color-red;
    text-align: right;
    padding-right: 2rem;
  }
}