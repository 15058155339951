.workflow {
  position: relative;
  display: flex;
  z-index: 2;
  margin-top: 2rem;
  font-size: 1rem;

  @media screen and (max-width: $lg) {
    flex-wrap: wrap;
  }

  &__box {
    position: relative;
    height: 15em;
    width: 13em;
    box-shadow: none;
    text-align: left;
    text-transform: uppercase;
    transition: all ease-in-out 0.175s;
    border: none;
    padding: 2em;
    color: #000;

    // set width for every single svg for it to display in safari
    &:first-child {

      .box-end-nth {
        display: block;
        width: 1.6rem;

        @media screen and (max-width: $sm) {
          width: 1.1rem;
        }
      }

      .box-start-first {
        width: 1.2rem;
        display: block;

        @media screen and (max-width: $sm) {
          width: 0.79rem;
        }
      }

    }

    &:not(:last-child, :first-child) {

      .box-start-nth {
        width: 1.2rem;
        display: block;

        @media screen and (max-width: $sm) {
          width: 0.79rem;
        }
      }

      .box-end-nth {
        width: 1.6rem;
        display: block;

        @media screen and (max-width: $sm) {
          width: 1.1rem;
        }
      }
    }

    &:last-child {

      .box-start-nth {
        display: block;
        width: 1.2rem;

        @media screen and (max-width: $sm) {
          width: 0.79rem;
        }
      }

      .box-end-last {
        display: block;
        width: 2.9rem;

        @media screen and (max-width: $sm) {
          width: 1.9rem;
        }
      }
    }

    &:last-child {
      width: 15.4em;
    }

    @media screen and (max-width: $lg) {
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: $sm) {
      width: 100%;
      height: 126px;
      margin-right: 0;
      background-image: none !important;

      &:last-child {
        width: 100%;
      }
    }

    // embedding of svgs as border left and right of progress boxes
    &__background,
    &__background--collection {
      position: absolute;
      inset: 0;
      display: flex;
      z-index: -1;
      width: 100%;

      svg {
        // flex-shrink: 0;
        display: none;
      }

      // workflow-box border oben und unten
      div {
        background: $color-white;
        border-color: $color-dark;
        border-style: solid;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-right: none;
        border-left: none;
        flex: 1;
      }
    }

    &__background--collection {
      transform: translate(-6px, -4px);
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @media screen and (max-width: $sm) {
        flex-direction: row;

        :first-child {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      &__iconarea {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        justify-content: flex-start;
        align-items: center;
      }

      &__text {
        margin-top: 1em;
        line-height: normal;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media screen and (max-width: $sm) {
          justify-content: center;
          align-items: center;
        }

        &__project-milestone-prefix {
          font-size: 0.875em;
          color: $color-gray;
          font-weight: 600;
          letter-spacing: 0.05em;
        }

        &__title {
          font-size: 1.625em;
          font-weight: bold;
          margin: 0.25em 0 0.375em;
          line-height: 1.25;

          &--smaller {
            font-size: 1.1em;
          }
        }

        &__status {
          font-size: 0.7em;
          font-weight: 500;
        }
      }

      &__status-icon {
        align-self: center;
      }
    }

    // collection of a bunch of workflow-boxes, displayed as a little pile
    &--collection {
      &::before {
        content: '';
      }
    }

    &:focus-visible {
      box-shadow: none !important;
    }
  }

  .inactive {
    color: $color-gray-light;
    border-color: $color-gray-light;

    .workflow__box__background>div {
      border-color: $color-gray-light;
    }

    .workflow__box__body__text__project-milestone-prefix {
      color: $color-gray-light !important;
    }

    &::before {
      opacity: 0.4;
    }
  }
}

a.workflow__box:hover {
  margin-top: -0.75em;

  @media screen and (max-width: $sm) {
    margin-top: 0;
  }
}

a.workflow__box:focus-visible,
a.workflow__box:focus {
  margin-top: -0.75em;
  box-shadow: none;

  // change color of top and bottom border of the box, made as "border"
  div {
    border-color: $color-primary;
  }

  .workflow__box__background {

    // change color of right and left border of the box, made of svgs
    svg {
      color: $color-primary;
    }
  }

  @media screen and (max-width: $sm) {
    margin-top: inherit;
  }
}

.sub-workflow {
  position: relative;
  display: flex;
  margin-left: 6rem;
  z-index: 0;
  margin-top: -5rem;

  @media screen and (max-width: $md) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: $sm) {
    margin: 0;
  }
}

a.sub-workflow__box {
  position: relative;
  width: 13em;
  background: $color-gray-lighter;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  height: 13em;
  border-radius: 0.75em;
  border: none;
  padding: 0 1em 1em 1.5em;
  margin-right: 1.25em;
  margin-top: 0;
  margin-bottom: 1.5em;
  text-transform: uppercase;
  box-shadow: none;
  cursor: pointer;
  transition: all ease-in-out 0.175s;

  @media screen and (max-width: $sm) {
    height: 126px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  &:hover,
  &:focus-visible {
    transform: translateY(0.5em);
    display: flex;
    background: $color-gray-light;

    @media screen and (max-width: $sm) {
      transform: none;
    }
  }

  h3 {
    font-size: 1.75em;
    font-weight: 600;
    margin-bottom: 1em;

    @media screen and (max-width: $sm) {
      margin: 0;
    }
  }

  &:first-child,
  &:not(:first-child):not(:last-child) {
    &::after {
      left: 100%;
      top: 50%;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-top: -17px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 14px solid $color-gray-lighter;

    }
  }

  &:not(:first-child):not(:last-child),
  &:last-child {
    &::before {
      left: -4px;
      top: 50%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-top: -17px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 14px solid $color-white;
    }
  }

  &:last-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 14rem;

    @media screen and (max-width: $sm) {
      width: 90%;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-top: 7rem solid transparent;
      border-left: 30px solid $color-gray-lighter;
      border-right: 10px solid transparent;
      border-bottom: 6rem solid transparent;
      transition: all ease-in-out 0.175s;

      @media screen and (max-width: $sm) {
        border-top: 4.5rem solid transparent;
        border-bottom: 4.5rem solid transparent;
      }
    }

    &:hover::after,
    &:focus-visible::after {
      border-left: 30px solid $color-gray-light !important;
    }
  }
}