.discussion-card {
  overflow: auto;

  &__title-section {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  // @todo Linda: Löschen, wenn https://futureprojects.atlassian.net/browse/FCP-1690 fertig 
  &__header--support-request {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__icon {
      margin: auto 2rem auto 0rem;
    }
  }
}

.feedback-post {
  padding-left: 1rem;
  margin-left: 0.9rem;
  margin-top: 1rem;
  border-left: 1px solid $color-dark;

  .date-time {
    color: $color-gray;
    display: inline-block;
    font-size: small;
  }

  &__header {

    &--initial-post {
      .user-name-bubble {
        &::before {
          width: 0px;
        }
      }
    }
  }

  // for all instances in feedback-post (not in modal) 
  .feedback-post__answer__form {
    margin-top: -1.5rem;
    margin-left: 1.625rem;
    margin-bottom: 3rem;
  }

  &__answer__btn {
    scale: 82%;
    margin-left: -2.438rem;
    margin-bottom: -0.625rem;
  }

  &__answers {
    margin-top: -1.8rem;
    margin-left: 1.625rem;
  }

  &__comment-type-container {
    margin-bottom: 2.5rem;
  }

  &__comment-type {
    background-color: $color-white;
    border: 1px solid $color-gray-light;
    padding: 0.5rem;
    border-radius: 9px;
    gap: 0.5rem;
    display: flex;
    transition: 0.2s;

    &:hover {
      background-color: $color-gray-lighter;
    }
  }

  .radio-icon--chosen {
    background-color: $color-gray-lighter;
    box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
  }
}