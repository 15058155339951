.user-name-bubble {
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0.6rem 0rem;
  text-align: center;
  font-size: 1rem;
  color: $color-white;
  font-weight: 700;
  margin-right: 1rem;
  margin-left: -2.4rem;
  display: inline-block;

  &::before {
    content: "";
    margin: 0.7rem -3.1rem;
    position: absolute;
    height: 1px;
    background: black;
    width: 34px;
  }
}

.user-profile {
  &__picture {
    img {
      border-radius: 9999px;
      width: 148px;
      height: 148px;
      object-fit: cover;
      box-shadow: $box-shadow-default;
    }

    // icon to change user profile
    &__change-icon {
      position: absolute;
      border-radius: 9999px;
      border: 1px solid $color-gray-light !important;
      padding: 0.4rem !important;
      background-color: $color-white !important;
      left: 60%;
      top: 6rem;

      @media screen and (min-width: $md) {
        left: 30%;
      }
    }
  }

  &__roles {
    border: solid 1px $color-dark !important;
    padding: 0.2rem;
  }
}