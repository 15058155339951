.project-images {
  border: 1px solid rgba($color-gray-medium, 0.5) !important;
  border-radius: 0.25rem;
  height: auto;
}

.public-project-profile {

  &__card {
    padding: 0 !important;
    overflow: hidden;

    .card-header {
      display: block;
      border-bottom: 2px solid $color-dark;
      padding: 0;

      .row {
        margin-bottom: 0;
      }

      &__left-col {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding: 3rem 1.5rem;
      }
    }

    .card-body {
      padding: 0 1.5rem;
    }

    .card-header+.card-body {
      padding-top: 2rem;
    }
  }

  &__team .member-count {
    font-weight: normal;
    font-size: 1.25rem;
  }

  &__picture {
    height: 600px;
    display: flex;
    background-color: $color-gray-lighter;

    &--placeholder {
      object-fit: contain;
      object-position: center;
      height: 100%;
      width: 100%;
      max-width: 24rem;
      margin: 0 auto;
    }

    @media screen and (max-width: $sm) {
      max-height: 300px;
    }
  }

  &__nav {
    max-width: 18rem;

    ul {
      list-style: none !important;
      padding: 1rem 0;
    }

    li+li {
      margin-top: 1.5rem;
    }

    a {
      font-family: $font-heading;
      font-weight: 500;
    }
  }

  &__visualization {
    position: relative;
    display: block;
    border: 1px solid $color-gray-medium !important;
    height: 150px;

    .icon {
      position: absolute;
      bottom: 0;
      left: 0;
      background: $white;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }

    &:hover .icon {
      opacity: 1;
    }
  }

  &--unfinished {
    border-top: 2px solid $color-gray-lighter;
    padding: 3rem 0 1.5rem;

    h3 {
      font-family: $font-heading;
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      font-family: $font-heading;
      font-weight: 500;
      max-width: 38rem;
      margin: 0 auto;
    }
  }
}